import { configureStore, combineReducers } from '@reduxjs/toolkit';
import VolsSlice from './slice/VolsSlice';


const rootReducer = combineReducers({
 Pack_vols : VolsSlice
  // Add other reducers here if needed
});

const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;