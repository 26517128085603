import React, { FC, SyntheticEvent, useState } from "react";
import wts from "../../images/wts.png";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/material";
import Input from "shared/Input/Input";
import { Api } from "Api/Api_base";
import { String } from "lodash";

export interface FlightCardProps {
  className?: string;
  defaultOpen?: boolean;
  defaultPop?:boolean;
  
  data: {
    id: number; // Update the data type for 'id' if it's a number
    nom_packs_vols: string;
    type: string;
    type_aller: string;
    date_aller: string;
    airport_aller: string;
    date_retour: string | null;
    type_retour: string | null;
    airport_destination: string;
    prix: number; // Update the data type for 'prix' if it's a number
    place: number; // Update the data type for 'place' if it's a number
    desc: string;
    company:string
    user_update: string | null;
    user_delete: string | null;
    user_add: string;
    deleted_at: string | null;
    created_at: string;
    updated_at: string;
  };
}
interface Airline {
  company: string;
  logo: string;
}

let airlineLogos: Airline[] = [
  {company: "Royal Air Maroc", logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/b/bf/Logo_Royal_Air_Maroc.svg/768px-Logo_Royal_Air_Maroc.svg.png"},
  {company: "Flynas", logo: "https://www.logo.wine/a/logo/Flynas/Flynas-Logo.wine.svg"},
  {company: "Saudi Arabian Airlines", logo: "https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcSOUYsxOh4DCbvL9gh_XoN0yBwDhM0MM2kmYyfkETABOI7TBvQ2"},
  {company: "Gulf Air", logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgDVqzCWiXxFSir6W2UskNMSTAzKH3vbfOTVjQXNlG&s"},
  {company: "Turkish Airlines", logo: "https://1000logos.net/wp-content/uploads/2020/04/Turkish_Airlines_logo.png"},
  {company: "Qatar Airways", logo: "https://upload.wikimedia.org/wikipedia/fr/thumb/c/cb/Logo_Qatar_Airways.svg/1200px-Logo_Qatar_Airways.svg.png"},
  {company: "Air Cairo" , logo: `${Api}company/airCairo.jpg`},
  {company: "Egypt Air" , logo: `${Api}company/EgyptAir.jpg`}
];




const FlightCard: FC<FlightCardProps> = ({
  className = "",
  data,
  defaultOpen = false,
  defaultPop= false,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [Pop_send , SetPop_send] = useState(defaultPop)
  const [Pack,SetPack]=useState('')

  const formatDate = (dateString: string | null) => {
    if (dateString === null) {
      return "N/A"; // Return a default value or handle the null case as needed
    }
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();

    return `${day}-${month}-${year} `;
  };

  function Env_message(e: SyntheticEvent<HTMLFormElement, Event>)  {
    e.preventDefault()
    const form = e.target as HTMLFormElement;
  const phoneNumber = "+212 665075049";
  const orderDetails = `Je souhaiterais commander un pack de vols, s'il vous plaît. Voici les détails de ma commande :
- Nom prenom: ${form.nom.value}
-email : ${form.email.value}
- Pack : ${Pack}
- Nombre de places :${form.place.value} `;

  const url = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
    orderDetails
  )}&app_absent=0`;

  window.open(url, "_blank");
  }

  function popap(){
    
    return(
      <Dialog open={Pop_send} onClose={()=>{SetPop_send(false)}}>
      <DialogTitle>Commande de Places de Pack Vol</DialogTitle>
      <Box component="form" onSubmit={Env_message}>
      <DialogContent>
        <DialogContentText>
        <span className="text-neutral-800 dark:text-neutral-200">
        Saisissez les information pour effectuer vous commander :
        </span>
        </DialogContentText>
            <label className="block">
              <Input
                type="text"
                placeholder="Nom prenom"
                className="mt-1"
                name="nom"
                required
              />
            </label>
            <label className="block">
              <Input
                type="email"
                placeholder="example@example.com"
                className="mt-1"
                name="email"
                required
              />
            </label>

            <label className="block">
              <Input
                type="number"
                placeholder="Number de place"
                className="mt-1"
                name="place"
                required
              />
            </label>
      </DialogContent>
      <DialogActions>
        <ButtonPrimary type="button" data-custom-type="nosubmit" onClick={()=>{SetPop_send(false)}}>Annuler</ButtonPrimary>
        <ButtonPrimary type="submit" >Commander</ButtonPrimary>
      </DialogActions>
      </Box>
    </Dialog>
    )
      
  }
  let foundAirline:Airline | undefined = airlineLogos.find((airline) => airline.company === data.company)
  let Array_desc:string[] =  data.desc.split(" - ")
  const renderDetailTop = () => {
    const formathour = (dateString: string) => {
      const date = new Date(dateString);
      const hour = date.getHours();
      const minute = date.getMinutes();

      return `${hour} hr ${minute} min `;
    };
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img
              src={foundAirline?.logo}
              className="w-10"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {formatDate(data.date_aller)} · {formathour(data.date_aller)}
                </span>
                <span className=" font-semibold">{data.airport_aller}</span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400"></span>
                <span className=" font-semibold">
                  {data.airport_destination}
                </span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
          {Array_desc && (
  <ul>
    {Array_desc.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
)}   
          </ul>
        </div>
      </div>
    );
  };

  const renderDetailbottom = () => {
    const formathour = (dateString: string | null) => {
      if (dateString === null) {
        return "N/A"; // Return a default value or handle the null case as needed
      }
      const date = new Date(dateString);
      const hour = date.getHours();
      const minute = date.getMinutes();

      return `${hour} hr ${minute} min `;
    };
    return (
      <div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-24 md:w-20 lg:w-24 flex-shrink-0 md:pt-7">
            <img
              src={foundAirline?.logo}
              className="w-10"
              alt=""
            />
          </div>
          <div className="flex my-5 md:my-0">
            <div className="flex-shrink-0 flex flex-col items-center py-2">
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
              <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
              <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            </div>
            <div className="ml-4 space-y-10 text-sm">
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400">
                  {formatDate(data.date_retour)} ·{" "}
                  {formathour(data.date_retour)}
                </span>
                <span className=" font-semibold">
                  {data.airport_destination}
                </span>
              </div>
              <div className="flex flex-col space-y-1">
                <span className=" text-neutral-500 dark:text-neutral-400"></span>
                <span className=" font-semibold">{data.airport_aller}</span>
              </div>
            </div>
          </div>
          <div className="border-l border-neutral-200 dark:border-neutral-700 md:mx-6 lg:mx-10"></div>
          <ul className="text-sm text-neutral-500 dark:text-neutral-400 space-y-1 md:space-y-2">
          </ul>
        </div>
      </div>
    );
  };

  const renderDetail = () => {
    if (!isOpen) return null;
    return (
      <div className="p-4 md:p-8 border border-neutral-200 dark:border-neutral-700 rounded-2xl ">
        {renderDetailTop()}
        {data.type === "Aller_Retour" ? (
          <>
            <div className="my-7 md:my-10 space-y-5 md:pl-24">
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
              <div className="text-neutral-700 dark:text-neutral-300 text-sm md:text-base">
                information retour de vol pack
              </div>
              <div className="border-t border-neutral-200 dark:border-neutral-700" />
            </div>
            {renderDetailbottom()}
          </>
        ) : null}
      </div>
    );
  };
  return (
    <div
      className={`nc-FlightCardgroup p-4 sm:p-6 relative bg-white dark:bg-neutral-900 border border-neutral-100
     dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-lg transition-shadow space-y-6 ${className}`}
      data-nc-id="FlightCard"
    >
      <div
        className={` sm:pr-20 relative  ${className}`}
        data-nc-id="FlightCard"
      >
        {/*  eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a href="##" className="absolute inset-0" />

        <span
          className={`absolute right-0 bottom-0 sm:bottom-auto sm:top-1/2 sm:-translate-y-1/2 w-10 h-10 bg-neutral-50 dark:bg-neutral-800 rounded-full flex items-center justify-center cursor-pointer ${
            isOpen ? "transform -rotate-180" : ""
          }`}
          onClick={() => setIsOpen(!isOpen)}
        >
          <i className="text-xl las la-angle-down"></i>
        </span>

        <div className="flex  flex-col sm:flex-row sm:items-center space-y-6 sm:space-y-0">
          {/* LOGO IMG */}
          <div className="w-24 lg:w-32 flex-shrink-0">
            <img
              src={foundAirline?.logo}
              className="w-20"
              alt=""
            />
          </div>

          {/* FOR MOBILE RESPONSIVE */}
          <div className="block lg:hidden space-y-1">
          <div className="flex font-semibold">
              <div>
                <span>{data.nom_packs_vols}</span>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                {formatDate(data.date_aller)}
                </span>
              </div>
              </div>

            <div className="flex font-semibold">
              <div>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {data.airport_aller}
                </span>
              </div>
              <span className="w-12 flex justify-center">
                <i className=" text-2xl las la-long-arrow-alt-right"></i>
              </span>
              <div>
                <span className="flex items-center text-sm text-neutral-500 font-normal mt-0.5">
                  {data.airport_destination}
                </span>
              </div>
            </div>

            <div className="text-sm text-neutral-500 font-normal mt-0.5">
            {data.type === "Aller_simple" ? (<>
             <span className="VG3hNb">Aller simple</span>
              <span className="mx-2">·</span>
              <span>{data.type_aller}</span>
             </>

            ):(
              <>
              <span className="VG3hNb">Aller Retour </span>
              <span className="mx-2">·</span>
              <span>A : {data.type_aller}</span>
              <span className="mx-2">·</span>
              <span>R : {data.type_retour}</span></>

            )}
             
            </div>
          </div>

          {/* TIME - NAME */}
          <div className="hidden lg:block  min-w-[150px] flex-[4] ">
            <div className="font-medium text-lg"> {data.nom_packs_vols}</div>
            <div className="text-sm text-neutral-500 font-normal mt-0.5">
            {formatDate(data.date_aller)}
            </div>
            
          </div>

          {/* TIMME */}
          <div className="hidden lg:block flex-[4] min-w-[250px] ">
            <div className="font-medium text-lg">
              
              - {data.airport_aller}

            </div>
            <div className="font-medium text-lg">  
              - {data.airport_destination}
            </div>
          </div>
      
          {/* TYPE */}
          <div className="hidden lg:block flex-[4] whitespace-nowrap">
            {data.type === "Aller_simple" ? (
              <>
                <div className="font-medium text-lg"> Aller simple</div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                  {data.type_aller}
                </div>
              </>
            ) : (
              <>
                <div className="font-medium text-lg"> Aller Retour</div>
                <div className="text-sm text-neutral-500 font-normal mt-0.5">
                 A : {data.type_aller} - R : {data.type_retour}
                </div>
              </>
            )}
          </div>

          {/* PRICE */}
          <div className="flex-[4] whitespace-nowrap sm:text-right">
            <div>
              <span className="text-2xl font-semibold text-secondary-6000">
                {data.prix} dh
              </span>
            </div>
            <div>
            <ButtonPrimary onClick={() =>{ SetPack(data.nom_packs_vols)  ; SetPop_send(true) } }>Reserve</ButtonPrimary> 
            </div>
          </div>
        </div>
      </div>

      {/* DETAIL */}
      {renderDetail()}

    {popap()}
    </div>
  );
};

export default FlightCard;
