import { createSlice , createAsyncThunk } from '@reduxjs/toolkit';
import { Api_base } from 'Api/Api_base';
import axios from 'axios';

export interface Data {
  
  id: number; // Update the data type for 'id' if it's a number
  nom_packs_vols: string;
  type: string;
  type_aller: string;
  date_aller: string;
  airport_aller: string;
  date_retour: string | null;
  type_retour: string | null;
  airport_destination: string;
  prix: number; // Update the data type for 'prix' if it's a number
  place: number; // Update the data type for 'place' if it's a number
  desc: string;
  company:string
  user_update: string | null;
  user_delete: string | null;
  user_add: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
};

export interface tags{
  name:string
}







export const Get_pack_vols= createAsyncThunk("get pack vols" , async(data:string[])=>{
     const response = await axios.get(`${Api_base}/client/vols/pack_vols`, {
      params: {
        arrayParam: data
      }
    })
     return response.data
})

export const Get_tags_vols = createAsyncThunk("get tags vols" , async()=>{
  const response = await axios.get(`${Api_base}/tag/tags/pack/pack_vol`)
  return response.data
})


interface PackState {
  loading:boolean,
  Pack_vols:Data[],
  Tags:tags[]
}

const initialState: PackState = {
 loading:false,
 Pack_vols:[],
 Tags:[]
};

const VolsSlice = createSlice({
  name: 'pack',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(Get_pack_vols.pending, (state) => {
      state.loading = true;
    })
    .addCase(Get_pack_vols.fulfilled, (state, action) => {
      state.loading = false;
      state.Pack_vols = action.payload;
    })
    .addCase(Get_pack_vols.rejected, (state) => {
      state.loading = false;
    })
    .addCase(Get_tags_vols.pending, (state) => {
      state.loading = true;
    })
    .addCase(Get_tags_vols.fulfilled, (state, action) => {
      state.loading = false;
      state.Tags = action.payload;
    })
    .addCase(Get_tags_vols.rejected, (state) => {
      state.loading = false;
    })
}});

export default VolsSlice.reducer;